
require('@/assets/main.scss');
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import './registerServiceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe, faMobile, faHeart, faHandshake, faCommentsDollar, faFileInvoiceDollar, faPaperPlane, faWallet, faUserCheck, faUser, faInfoCircle, faHandSpock, faArrowLeft, faArrowRight, faSlidersH, faMapPin, faAngleDown, faCheck, faCopy, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faCanadianMapleLeaf,faLinkedin, faTwitter, faInstagram, faYoutube, faGithub, faReddit, faDiscord, faApple, faAndroid } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'

library.add(faGlobe, faApple, faAndroid, faMobile, faHeart, faCanadianMapleLeaf, faHandshake, faCommentsDollar, faFileInvoiceDollar, faPaperPlane, faWallet, faUserCheck,faUser, faInfoCircle, faHandSpock, faLinkedin, faTwitter, faInstagram, faYoutube, faGithub, faReddit, faArrowLeft, faArrowRight, faDiscord, faSlidersH, faMapPin, faAngleDown, faCheck, faCopy, faSignOutAlt)

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.$http = axios
app.config.productionTip = false
app.use(router).mount('#app');

