<template>
  <div>
    <section class="section">
      <img alt="geckopay" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/GeckoPay_Logo_Gradient.png?alt=media" style="width:700px">
      <h2 class="subtitle is-4">
        Multi-currency negotiation process. Pay anyone, anywhere, anytime.
      </h2>
    </section>

    <section class="section">
      <h2 class="title is-2">
        Electronic Payment Solutions <br> to meet all your individual and business needs
      </h2>
      <div class="container has-text-left">
        <div class="columns">
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Pay Contractors</span>
          </div>
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Pay Employees</span>
          </div>
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Pay businesses</span>
          </div>
        </div>
        <div class="columns">
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Provide Payment Details</span>
          </div>
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Multi-currency Negotiation Process</span>
          </div>
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">AI Fraud Detection</span>
          </div>
        </div>
        <div class="columns">
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Cryptocurrency and Fiat Currency Options</span>
          </div>
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Account Statement for Tax Purposes</span>
          </div>
          <div class="column is-1">
            <font-awesome-icon :icon="['fas', 'check']" size="2x" color="#8AA519" />
          </div>
          <div class="column is-3">
            <span class="subtitle is-4">Save Time and Money</span>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <h2 class="title is-2">
        Pricing
      </h2>
      <div class="pricing-table is-comparative">
        <div class="pricing-plan is-features">
          <div class="plan-header">Features</div>
          <div class="plan-price"><span class="plan-price-amount">&nbsp;</span><br>&nbsp;</div>
          <div class="plan-items">
            <div class="plan-item">Transfer Funds<br><span class="plan-price-amount">&nbsp;</span></div>
            <div class="plan-item">Cryptocurrency Portfolio</div>
            <div class="plan-item">Cryptocurrency Conversions</div>
            <div class="plan-item">Spending Limit per day</div>
            <div class="plan-item">Historical Transaction Report</div>
            <div class="plan-item">Multi-Accounts Transaction at a time</div>
            <div class="plan-item">Transaction Notification</div>
            <div class="plan-item">GeckoPay API Documentation Access<br><br><span class="plan-price-amount">&nbsp;</span></div>
            <div class="plan-item">Downloadable Account Statement</div>
          </div>
          <div class="plan-footer"></div>
        </div>
        <div class="pricing-plan">
          <div class="plan-header">Base Plan</div>
          <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency"></span>Free</span><br>&nbsp;</div>
          <div class="plan-items">
            <div class="plan-item" data-feature="Transfer Funds">Crypto &amp; Fiat<br>(5% service fee per transaction)</div>
            <div class="plan-item" data-feature="Cryptocurrency Portfolio">V</div>
            <div class="plan-item" data-feature="Cryptocurrency Conversions">V</div>
            <div class="plan-item" data-feature="Spending Limit">CAD 1000</div>
            <div class="plan-item" data-feature="Historical Transaction Report">up to 3 months</div>
            <div class="plan-item" data-feature="Multi-Accounts Transaction at a time">-</div>
            <div class="plan-item" data-feature="Transaction Notification">Email</div>
            <div class="plan-item" data-feature="GeckoPay API documentation access">100,000 daily requests <br><br>no exceeds allowed</div>
            <div class="plan-item" data-feature="Downloadable Account Statement">-</div>
          </div>
          <div class="plan-footer">
            <button class="button is-fullwidth is-static">Current plan</button>
          </div>
        </div>

        <div class="pricing-plan is-active">
          <div class="plan-header">Professional Plan</div>
          <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>50</span>/month, billed annually
            <br>$55/month, billed monthly</div>
          <div class="plan-items">
            <div class="plan-item" data-feature="Transfer Funds">Crypto &amp; Fiat<br>(5% service fee per transaction)</div>
            <div class="plan-item" data-feature="Cryptocurrency Portfolio">V</div>
            <div class="plan-item" data-feature="Cryptocurrency Conversions">V</div>
            <div class="plan-item" data-feature="Spending Limit">CAD 3000</div>
            <div class="plan-item" data-feature="Historical Transaction Report">up to 1 year</div>
            <div class="plan-item" data-feature="Multi-Accounts Transaction at a time">V</div>
            <div class="plan-item" data-feature="Transaction Notification">Email &amp; SMS</div>
            <div class="plan-item" data-feature="GeckoPay API documentation access">200,000 Requests/Day <br><br>CAD 0.40 / beyond 200,000 requests</div>
            <div class="plan-item" data-feature="Downloadable Account Statement">-</div>
          </div>
          <div class="plan-footer">
            <button class="button is-fullwidth" disabled="disabled">Choose</button>
          </div>
        </div>

        <div class="pricing-plan is-success">
          <div class="plan-header">Business Plan</div>
          <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>225</span>/month, billed annually
            <br>$230/month, billed monthly</div>
          <div class="plan-items">
            <div class="plan-item" data-feature="Transfer Funds">Crypto &amp; Fiat<br>(5% service fee per transaction)</div>
            <div class="plan-item" data-feature="Cryptocurrency Portfolio">V</div>
            <div class="plan-item" data-feature="Cryptocurrency Conversions">V</div>
            <div class="plan-item" data-feature="Spending Limit">CAD 5000+</div>
            <div class="plan-item" data-feature="Historical Transaction Report">up to 3 years</div>
            <div class="plan-item" data-feature="Multi-Accounts Transaction at a time">V</div>
            <div class="plan-item" data-feature="Transaction Notification">Email &amp; SMS</div>
            <div class="plan-item" data-feature="GeckoPay API documentation access">1,000,000 Requests/Day <br><br>CAD 0.40 / beyond 1 million requests </div>
            <div class="plan-item" data-feature="Downloadable Account Statement">V</div>
          </div>
          <div class="plan-footer">
            <button class="button is-fullwidth" disabled="disabled">Choose</button>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <h2 class="subtitle is-4">
        Need help? Contact us at <a href="mailto:info@geckopay.co" style="color:#8AA519;">info@geckopay.co</a>
      </h2>
      <div class="container">
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'price-page',
  methods: {
    goToDownload() {
      this.$router.push('/download').catch({});
    }
  }
}
</script>
