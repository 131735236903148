import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import ToS from '@/components/ToS'
import Home from '@/components/Home'
import SignIn from '@/components/SignIn'
import Product from '@/components/Product'
import Price from '@/components/Price'
import Privacy from '@/components/Privacy'
import Faq from '@/components/FAQ'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home-page',
      component: Home,
    },
    {
      path: '/terms',
      name: 'tos-page',
      component: ToS,
    },
    {
      path: '/signin',
      name: 'signin-page',
      component: SignIn,
    },
    {
      path: '/product',
      name: 'product-page',
      component: Product,
    },
    {
      path: '/price',
      name: 'price-page',
      component: Price,
    },
    {
      path: '/privacy',
      name: 'privacy-page',
      component: Privacy,
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
    },
  ],
  scrollBehavior() {
    window.scrollTo(0,0);
  }
});


export default router;
