<template>
  <div>
    <section class="section is-medium">
      <img alt="geckopay" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/GeckoPay_Logo_Gradient.png?alt=media" style="width:500px"><br><br>
      <h1 class="title is-1">
        Coming Soon!
      </h1>
      <h2 class="subtitle is-4">
        Welcome to gecko-beta-1.0
      </h2>

      <div class="columns has-text-left">
        <div class="column is-one-quarter">
        </div>
        <div class="column is-half">
          <form class="box" v-on:submit.prevent>
            <div class="field">
              <div class="control">
                <label class="label">Password</label>
                <input class="input is-primary" type="password" placeholder="Password" disabled>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="label">12 Secret Seeds</label>
                <textarea class="textarea is-primary" placeholder="Separate each word with a single space" disabled></textarea>
              </div>
            </div>
            <br>
            <div class="field">
              <div class="control">
                <input class="is-checkradio" id="rememberMe" type="checkbox" name="rememberMe" disabled>
                <label for="rememberMe">Remember me</label>
              </div>
            </div><br>

            <button class="button is-primary is-fullwidth is-medium" disabled>Sign In</button><br>
            <p>Need an account? <a style="color:#8AA519;">Register for free now</a></p><br><br>
          </form>
        </div>
        <div class="column is-one-quarter">
        </div>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: 'signin-page',
  data () {
    return {
    }
  },
  methods: {
    goToS() {
      this.$router.push('/terms').catch({});
    },
  }
}
</script>
