<template>
  <div id="app">
    <gecko-nav />
    <router-view/>
    <gecko-footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Nav from '@/components/Nav.vue';

export default {
  name: 'App',
  components: {
    'gecko-footer': Footer,
    'gecko-nav': Nav
  },
  data () {
    return {
    }
  },
}
</script>

<style>
/**src='@/assets/main.scss' */
body {
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 50px;
}

#menu-main-logo {
  background: url('https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/Logo_03.svg?alt=media') no-repeat center center;
  width: 80px;
  margin-left: 10px;
}

.icon-margin {
  margin: 10px;
}

.icon-margin:hover {
  color: #D3D929;
}
</style>
