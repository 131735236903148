<template>
  <div>
    <section class="section is-medium" id="home">
        <div class="container">
          <img alt="geckopay" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/GeckoPay_Logo_Gradient.png?alt=media" style="width:500px">
              <h1 class="title is-2">
                Software Development in Kelowna!
              </h1>
              <h5 class="subtitle is-4">Specialize in blockchain and AI app development</h5>
              <button class="button is-primary is-outlined is-rounded is-large" @click="goToProduct">
                Check out Product List
              </button>
        </div>
      </section>

      <section class="section is-medium about-bg" id="about">
        <div class="container">
          <h1 class="title is-2 has-text-white">
            Our Services
          </h1>
          <p>GeckoPay is a self-funded team focused on design and app development.</p>
          <p>At GeckoPay, our experienced team is currently focused on creating exciting applications that seamlessly integrate with AI and blockchain technology. We handle everything from app architecture and database design to backend API development, authentication systems, AI prompt engineering, and frontend flutter app creation. </p>
          <br>
          <div class="columns">
            <div class="column is-4">
              <img alt="benefit02" src="https://cdn.midjourney.com/d0c3366b-dd6f-4ae4-8116-485d01b2ca0c/0_2.webp">
              <h2 class="title is-4 has-text-white">Mobile Application Development</h2>
            </div>
            <div class="column">
              <img alt="benefit02" src="https://cdn.midjourney.com/9c997fb4-6796-49f9-8bff-c28f0a04a863/0_3.webp">
              <h2 class="title is-4 has-text-white">Web Development</h2>
            </div>
            <div class="column">
              <img alt="benefit02" src="https://cdn.midjourney.com/890d2f09-4e98-4f90-bf6e-cb34a96268aa/0_0.webp">
              <h2 class="title is-4 has-text-white">Blockchain & AI Integration</h2>
            </div>
          </div> 
        </div>
      </section>

      <section class="section is-medium">
        <div class="container">
          <h1 class="title is-2">
            Contact Us
          </h1>
          <h2 class="subtitle is-4">Services that provide custom software development for businesses, from the initial design and planning stages to the implementation and maintenance of the software.</h2>
          <p>For product support or questions: <strong>info@geckopay.co</strong></p>
        </div>
      </section>
  </div>
</template>

<script>
import HomeHeader from '../components/HomeHeader'

export default {
  name: 'home-page',
  components: {
    HomeHeader
  },
  methods: {
    goToProduct() {
      this.$router.push('/product').catch({});
    }
  }
}
</script>

<style>
.section-wrap {
  padding: 20px;
}

.rm-bg {
  background-color: #000;
  color: #fff;
}

.rm-timeline {
  z-index: 1;
}

.round_img {
  border-radius: 20%;
  width: 18rem;
  height: 18rem;
  padding: 10px;
  /* border: 5px solid #fff; */
  background-color: #D3D929;
  margin: 0.5rem;
}
</style>
