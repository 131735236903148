<template>
  <div>
    <div class="is-hidden-touch has-text-center">
        <div class="homeSection">
          <section class="section is-medium" id="home">
            <div class="container">
              <div class="columns">
                <div class="column is-8 home-logo">
                  <img alt="geckopay" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/GeckoPay_Logo_Gradient.png?alt=media" style="width:500px">
                  <h1 class="title is-2">
                    Electronic Payment System
                  </h1>
                  <h5 class="subtitle is-4">Multi-currency negotiation process. <br> Pay anyone, anywhere, anytime.</h5>
                  <button class="button is-primary is-outlined is-rounded is-large" @click="goToProduct">
                    Check out Product List
                  </button>
                </div>
                <div class="column">
                  <div class="topSectionIllustration">
                    <img alt="topSection" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/Asset 20.svg?alt=media">
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> <!--homeSection-->
    </div><!--is-hidden-mobile-->

    <div class="is-hidden-desktop">
      <div id="container">
        <div class="homeSection">
          <section class="section is-large" id="home">
            <img alt="geckopay" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/GeckoPay_Logo_Gradient.png?alt=media" style="width:500px">
            <h1 class="title is-1">
              Electronic Payment System
            </h1>
            <h5 class="subtitle is-3">Multi-currency negotiation process. <br>Pay anyone, anywhere, anytime.</h5>
            <button class="button is-primary is-outlined is-rounded is-large" @click="goToProduct">
              Check out Product List
            </button>
          </section>
        </div>
      </div>
    </div><!--is-hidden-desktop-->

    <div class="aboutSection">
      <section class="section about-bg" id="about">
        <div class="container">
          <div class="about-block">
            <h1 class="title is-1 has-text-white">
              What is GeckoPay?
            </h1>
            <div class="columns">
              <div class="column is-6">
                <img alt="welcome" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/Welcome_Illustration.png?alt=media">
              </div>
              <div class="column has-text-left is-size-4">
                <p>GeckoPay is an Electronic Payment System.
                  We have lower fees and provide an option for you to issue or receive payment in cryptocurrency or fiat currency.</p><br>
                <p>We offer web and mobile solutions that allow transferring money with the simple click of a button!</p>
              </div>
            </div>
          </div>

          <div class="about-block">
            <h1 class="title is-1 has-text-white">
              Our beliefs
            </h1>
            <div class="columns">
              <div class="column is-size-4 has-text-left">
                <p>Everything we do, we believe in challenging the status quo.</p><br>
                <p>The way we challenge the status quo is by creating a economic opportunity for both cryptocurrency and fiat currency, incentivizing community to co-create a system that beneficial to every single living beings.</p>
              </div>
              <div class="column is-6">
                <img alt="belief" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/Belief_Illustration.png?alt=media">
              </div>
            </div>
          </div>

          <div class="about-block">
            <h1 class="title is-1 has-text-white">
              Our Method
            </h1>
            <div class="columns">
              <div class="column is-6">
                <img alt="method" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/Method_Illustration.png?alt=media">
              </div>
              <div class="column has-text-left is-size-4">
                <p>Our mission is to create healthy eco-system for cryptocurrency and fiat currency. Our value proposition is about accessibility and convenience.</p><br>
                <p>We support top traded cryptocurrency and different countries payement.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> <!--aboutSection-->
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    goBuyGKP() {
      this.$router.push('/buygkp').catch({});
    },
    goToProduct() {
      this.$router.push('/product').catch({});
    }
  }
}
</script>

<style>
.about-bg {
  background-color: #8AA519;
  color: #fff;
}

.getTokenButton {
  background: url('https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/Get-GeckoPay-Token_Button.svg?alt=media') no-repeat center center;
  height: 150px;
  width: 250px;
  position: absolute;
  top: 90%;
  left: 120px;
}

.home-logo {
  z-index: 1;
}

.about-block {
  padding: 50px;
}

div.homeSection {
  display: block;
}

div.aboutSection {
  display: block;
}

div.topSectionIllustration {
  width: 55%;
  position: absolute;
  right: -10%;
  top: 80%;
  transform: translateY(-50%);
}
</style>
