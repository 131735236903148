<template>
  <div>
    <section class="section">
      <img alt="geckopay" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/GeckoPay_Logo_Gradient.png?alt=media" style="width:700px">
      <h2 class="title is-1">
        FAQ
      </h2>
    </section>

    <section class="section about-bg">
      <div class="container has-text-left">
        <ol class="listIndent">
          <div class="wordWrap">
            <li class="title is-4 has-text-white">What is GeckoPay?</li>
            <p>
              GeckoPay is an Electronic Payment System. It offers multi-currency negotiation process and enables Users to pay anyone, anywhere, anytime.
            </p><br>
            <p>
              Our value proposition is about accessibility. We are not here to replace, we are here to add convenience and availability to the Users.
              Our vision is to see people comfortably use both cryptocurrency and fiat currency in their daily purchases.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">Who are GeckoPay target Users?</li>
            <p>
              We have two target user segments: Service Providers and Service Buyers.
            </p><br>
            <p>
              Service Providers are the Users who offer the service, for example, employees, contractors, coffee shop or restaurant owners.
            </p><br>
            <p>
              Service Buyers are the Users who pay the service, for example, employers, customers of coffee shops or restaurants.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">What service does GeckoPay offer?</li>
            <p>
              GeckoPay is a gateway pay system and mainly focuses on Crypto Transfering.
            </p><br>
            <p>
              When Service Providers and Service Buyers agree to do transactions through GeckoPay, Service Providers will issue an invoice through GeckoPay App, GeckoPay will then send the invoice to Service Buyers, Service Buyers then verify the invoice content and sign a payment Order, The GeckoPay system then verifies the payment Order signature and processes it to the blockchain network. Once the payment Order is processed, a transaction history record will be logged in Service Providers and Service Buyers GeckoPay Accounts.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">Is GeckoPay free?</li>
            <p>
              We have 3 plans: Base Plan, Professional Plan, and Business Plan.
            </p><br>
            <p>
              GeckoPay Services charge a 5% service fee on each transaction being made through the GeckoPay system. The 5% service fee is only charged on the Service Buyers side, you can think of it as a tip to the GeckoPay developers.
            </p><br>
            <p>
              As Professional Plan and Business Plan Users, we also charge a monthly fee for extended features that benefit businesses.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">What payment option does GeckoPay App offer?</li>
            <p>
              GeckoPay App offers cryptocurrency and fiat currency payment options.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">Can GeckoPay do crypto-to-crypto trading and fiat-to-crypto trading?</li>
            <p>
              Crypto-to-crypto trading and fiat-to-crypto trading are not a feature in GeckoPay Services. We may consider adding these features if there is demand from our Users.
            </p><br>
            <p>
              If you are looking for crypto-to-crypto and fiat-to-crypto trading you will need to visit a cryptocurrencies trading platform. After you obtain the cryptocurrency you can transfer it to the GeckoPay wallet, and then you can start using GeckoPay Services with the cryptocurrency you’ve transferred.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">Why does GeckoPay Services only support a few cryptocurrencies?</li>
            <p>
              GeckoPay Services currently support:
            </p>
            <ul class="listIndent">
              <li>- ETH (Ethereum)</li>
              <li>- BNB (Binance)</li>
              <li>- SOL (Solana)</li>
            </ul><br>
            <p>
              GeckoPay Services only work with the top traded and stable market cryptocurrencies. We would like to avoid any scheme or fraud affecting our Users. Our team is constantly monitoring and researching new stable cryptocurrency to add to GeckoPay Services. However, if you see a cryptocurrency that you wish to be supported in GeckoPay Services, please feel free to send a request to info@geckopay.co and our team will review it if it is qualified.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">Can children use GeckoPay Services?</li>
            <p>
              GeckoPay does not allow anyone under the age of 16 to use GeckoPay Services.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">Does GeckoPay Services do Canadian Revenue Agency (CRA)  integration and what are the tax implications?</li>
            <p>
              As an individual Users, if you use GeckoPay Services for simple funds transfer then you are not required to file tax on the activities you handle through GeckoPay Services.
            </p><br>
            <p>
              As an business owners Users, if you use GeckoPay Services to do payroll or receiving income, then you are required to file tax on the activities you handle through GeckoPay Services.
              You have access to a downloadable account statement of transaction data for tax purposes.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">How does GeckoPay apply GDPR?</li>
            <p>
              Email address and phone number are the 2 required fields when Users register a GeckoPay Account. After the account registration, we highly recommend Users to set up 2 steps verification to protect GeckoPay Account.
            </p><br>
            <p>
              To comply with our legal obligations under applicable laws and regulations, and anti-money laundering laws and regulations, we are obligated to store Users email address and phone number.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">How secure is GeckoPay Services?</li>
            <p>
              We design our systems with your security and privacy in mind.  We work to protect the security of your personal information during transmission by using encryption protocols and software.
            </p><br>
            <p>
              User accounts are protected with password and 2 steps verification with email address and phone number. Crypto Transfering can only be issued under Users’ consent and key signature. GeckoPay Services store Users information in an encrypted hash.
            </p>
          </div>
          <div class="wordWrap">
            <li class="title is-4 has-text-white">What is the Public Address I see on GeckoPay, is it safe to share?</li>
            <p>
              The Public Address on your GeckoPay Accounts dashboard is safe to share. Others can only send cryptocurrency to your Public Address, The Private Key is required to take your money. We highly recommend that you do NOT share your Private Key to anyone.
            </p><br>
            <p>
              The crypto Public Address is like your email address in the real world. Others can only send you but can’t take from you, unless you accidentally expose your password (Private Key).
            </p><br>
            <p>
              If you spot a suspicious or unauthorized event please contact the GeckoPay team immediately to freeze the GeckoPay Account.
            </p>
          </div>
        </ol>
      </div>
    </section>

  </div>
</template>
<script>
export default {
  name: 'faq'
}
</script>
