<template>
  <div>
    <footer class="footer has-text-white">
      <div class="content">
        <p>
          <a @click="goToS">Terms</a> | <a @click="goPrivacy">Privacy</a> 
        </p>
        <br>
        <p>&copy; {{ new Date().getFullYear() }} GECKOPAY TECHNOLOGY LTD. All rights reserved.</p>
        <p>Made with <span><font-awesome-icon :icon="['fas', 'heart']" /></span> in Canada <span><font-awesome-icon :icon="['fab', 'canadian-maple-leaf']" color="red" /></span></p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    goToS() {
      this.$router.push('/terms').catch({});
    },
    goPrivacy() {
      this.$router.push('/privacy').catch({});
    },
    goFAQ() {
      this.$router.push('/faq').catch({});
    },
    goGithub() {
      window.open("https://github.com/GeckoPay", '_blank');
    }
  }
}
</script>
