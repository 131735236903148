<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/" id="menu-main-logo"></a>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item">
          <a class="navbar-item" href="/"><strong>Home</strong></a>
          <a class="navbar-item" href="/#about"><strong>About</strong></a>
          <a class="navbar-item" @click="goToProduct"><strong>Product</strong></a>
          <font-awesome-icon :icon="['fab', 'instagram']" size="2x" class="icon-margin" @click="goInstagram" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    goInstagram() {
      window.open("https://www.instagram.com/geckopay.co/", '_blank');
    },
    goToS() {
      this.$router.push('/terms').catch({});
    },
    goGithub() {
      window.open("https://github.com/GeckoPay", '_blank');
    },
    goToProduct() {
      this.$router.push('/product').catch({});
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }
});
</script>
