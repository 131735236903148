<template>
  <div>
    <section class="section">
      <img alt="geckopay" src="https://firebasestorage.googleapis.com/v0/b/geckopay.appspot.com/o/GeckoPay_Logo_Gradient.png?alt=media" style="width:700px">
      <h2 class="subtitle is-4">
        Specialize in blockchain and AI app development
      </h2>
    </section>

    <section class="section">
      <h2 class="title is-2">
        Product List
      </h2>
      <div class="container has-text-left">
        <table class="table is-bordered is-fullwidth">
          <tbody>
            <!-- <tr>
              <th>1</th>
              <td>GeckoPay Web</td>
              <td>GeckoPay Web App.
                We enable service providers and service buyers to transfer crypto with the simple click of a button.
                We provide downloadable account statement report for business owners for tax purpose.
                <a style="color:#8AA519;" @click="goToPrice">Price</a>
              </td>
              <td>Coming Soon</td>
            </tr> -->
            <tr>
              <th></th>
              <th>Type</th>
              <th>App Name</th>
              <th>Description</th>
              <th>Links</th>
            </tr>
            <tr>
              <th>1</th>
              <td><font-awesome-icon :icon="['fas', 'mobile']" size="2xl" /></td>
              <td>MintGenie</td>
              <td>A content creation mobile app powered by AI.</td>
              <td>
                <font-awesome-icon class="clickable-header" :icon="['fab', 'apple']" size="2xl" @click="goToMGAppStore" /><br>
                <font-awesome-icon class="clickable-header" :icon="['fab', 'android']" size="2xl" @click="goToMGPlayStore" />
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td><font-awesome-icon :icon="['fas', 'mobile']" size="2xl" /></td>
              <td>MintyCookie</td>
              <td>CupidAI connects with your soulmate across the globe. Matchmaking and chat!</td>
              <td>
                <font-awesome-icon class="clickable-header" :icon="['fas', 'globe']" size="2xl" @click="goToMCWebsite" /><br>
                <font-awesome-icon class="clickable-header" :icon="['fab', 'apple']" size="2xl" @click="goToMCAppStore" /><br>
                <font-awesome-icon class="clickable-header" :icon="['fab', 'android']" size="2xl" @click="goToMCPlayStore" />
              </td>
            </tr>
            <tr>
              <th>3</th>
              <td><font-awesome-icon :icon="['fas', 'mobile']" size="2xl" /></td>
              <td>GeckoPay</td>
              <td>A crypto payment mobile app allows user to pay anyone, anywhere, anytime
              </td>
              <td>Coming Soon</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'product-page',
  methods: {
    // goToDownload() {
    //   this.$router.push('/download').catch({});
    // },
    // goToPrice() {
    //   this.$router.push('/price').catch({});
    // },
    goToMCWebsite() {
      window.open("https://mintycookie.com/", '_blank');
    },
    goToMCAppStore() {
      window.open("https://apps.apple.com/app/mintycookie/id6449194473", '_blank');
    },
    goToMCPlayStore() {
      window.open("https://play.google.com/store/apps/details?id=co.geckopay.mintycookie", '_blank');
    },
    goToMGAppStore() {
      window.open("https://apps.apple.com/app/mintgenieai/id6447470995", '_blank');
    },
    goToMGPlayStore() {
      window.open("https://play.google.com/store/apps/details?id=co.geckopay.mintgenieai", '_blank');
    },
  }
}
</script>

<style>
.clickable-header{
  cursor: pointer;
}
</style>